import * as singleSpa from 'single-spa';
import { GetMPMPortalAppRoutes } from './mpm-portal-app-routes';

let routes;

export async function pageNotFoundHandler() {
    routes = GetMPMPortalAppRoutes();
    window.addEventListener(
        "single-spa:before-routing-event",
        (event: any) => {
            const path = new URL(event.detail.newUrl)?.pathname;
            validRouteChangeHandler(routes, path);
        },
    );
}

function validRouteChangeHandler(routes, currentPath) {
  currentPath = currentPath.startsWith('/') ? currentPath.slice(1) : currentPath;
  if (findRoute(routes, currentPath) == null && currentPath !== "") {
      window.history.replaceState(null, '', '/page-not-found');
      singleSpa.navigateToUrl('/page-not-found');
  } 
}

function findRoute(routes, path) {
  return findRouteForPath(routes, path.split('/').filter(segment => segment));
}

function findRouteForPath(routes, pathSegments) {
  for (let route of routes) {
    const routeSegments = route.path.split('/').filter(segment => segment);

    if (matchSegments(routeSegments, pathSegments, route.children)) {
      if (route.children && pathSegments.length > routeSegments.length) {
        const found = findRouteForPath(route.children, pathSegments.slice(routeSegments.length));
        if (found != null) {
          return found;
        }
      } else {
        return route;
      }
    }
  }

  return null;
}

function matchSegments(routeSegments, pathSegments, hasChildren) {
  if (routeSegments.length > pathSegments.length) {
    return false;
  }
  if (pathSegments.length > routeSegments.length && !hasChildren) {
    return false;
  }

  for (let i = 0; i < routeSegments.length; i++) {
    if (routeSegments[i].startsWith(':')) {
      continue; 
    }
    if (routeSegments[i] !== pathSegments[i]) {
      return false;
    }
  }

  return true;
}

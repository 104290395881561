import { start } from "single-spa";
import { getConfiguration } from "./utils/configuration-service";
import { AppConfig } from "./utils/app-config";
import { ApplicationConfiguration } from "./utils/application-configuration.model";
import { registerApplications } from "./utils/single-spa-helper";
import { pageNotFoundHandler } from "./utils/page-not-found/page-not-found.handler";

import { CONTAINER_SCRIPT, SIGNIN_REDIRECTPATH_KEY } from './utils/constants';
declare var window;
declare var sessionStorage;
const address = window.location.pathname;
if(address === "/"){
  window.location.href = window.location + 'dashboard';
}
updateSignInRedirectPath();

(async function appStart(): Promise<void> {
  
  pageNotFoundHandler();
  // load config file
  await AppConfig.loadConfig();

  // load importMaps from api
  const applicationConfigurations = await getConfiguration();
  registerImportMaps(applicationConfigurations);

  await System.import(CONTAINER_SCRIPT);
  registerApplications(applicationConfigurations);

  start({
      urlRerouteOnly: false,
    });  
})();

function updateSignInRedirectPath(): void {
  let signInRedirect = JSON.parse(sessionStorage.getItem(SIGNIN_REDIRECTPATH_KEY));
  if(shouldUpdateRedirectPath(signInRedirect, address))
  {
  sessionStorage.setItem(SIGNIN_REDIRECTPATH_KEY, JSON.stringify(window.location.pathname + window.location.search ));
  }
}

function shouldUpdateRedirectPath(signInRedirectPath: string,
  address: string
): boolean {
return !signInRedirectPath && address !== "/"
}

function registerImportMaps(applications: ApplicationConfiguration[]): void {
  const imports: Record<string, string> = applications.reduce((acc, config) => {

      const existingOverride = window.importMapOverrides.getOverrideMap()?.imports[config.name];

      return {...acc, [config.name]: existingOverride != null ? existingOverride : config.location }
  }, {});

  const importMaps: System.ImportMap = {
      imports: imports
  };

  System.addImportMap(importMaps);

  Object.entries(importMaps?.imports).forEach(([name, location]) => {
    const overridesExist = window.importMapOverrides.getOverrideMap()?.imports[name];
    if (overridesExist) {
      return;
    }
    window.importMapOverrides.addOverride(name, location);
    window.importMapOverrides.disableOverride(name);
  });
}


export function GetMPMPortalAppRoutes() {

    const routes = [
        {
            path: 'auth-callback'
        },
        {
            path: 'callback'
        },
        {
            path: 'unauthorized'
        },
        {
            path: 'logout'
        },
        {
            path: 'session-expired'
        },
        {
            path: 'page-not-found'
        },
        //Admin
        {
            path: 'portal-admin/accountSummaries'
        },
        {
            path: 'portal-admin/accountSummaries/create'
        },
        {
            path: 'portal-admin/accountSummaries/edit/:id'
        },
        {
            path: 'portal-admin/roles'
        },
        {
            path: 'portal-admin/roles/create'
        },
        {
            path: 'portal-admin/roles/edit/:id'
        },
        {
            path: 'portal-admin/users'
        },
        {
            path: 'portal-admin/users/edit/:id'
        },
        {
            path: 'portal-admin/users/create'
        },
        {
            path: 'portal-admin/featureSetting/accounts'
        },
        {
            path: 'portal-admin/featureSetting/programs'
        },
        {
            path: 'portal-admin/modern-feature-setting/forecasting'
        },
        {
            path: 'portal-admin/modern-feature-setting/demandplans'
        },
        {
            path: 'portal-admin/modern-feature-setting/payments'
        },
        {
            path: 'portal-admin/product-criteria'
        },
        {
            path: 'portal-admin/product-criteria/edit/:id'
        },
        {
            path: 'portal-admin/product-criteria/create'
        },
        {
            path: 'portal-admin/account-attributes-settings'
        },
        {
            path: 'portal-admin/account-attributes-settings/create'
        },
        {
            path: 'portal-admin/account-attributes-settings/edit/:id'
        },
        {
            path: 'portal-admin/sales'
        },
        {
            path: 'portal-admin/sales/edit/:id'
        },
        {
            path: 'portal-admin/sales/create'
        },
        {
            path: 'portal-admin/activities'
        },
        {
            path: 'portal-admin/resources'
        },
        {
            path: 'portal-admin/resources/edit/:id'
        },
        {
            path: 'portal-admin/resources/add/:type'
        },
        {
            path: 'portal-admin/theme'
        },
        {
            path: 'portal-admin/workflow-management'
        },
        {
            path: 'portal-admin/kpi-config-management'
        },
        //Accounts
        {
            path: 'accounts'
          },
          {
            path: 'accounts-old',
            children: [
              {
                path: ''
              }
            ]
          },
          {
            path: 'portal/approvals',
            pathMatch: 'full',
          },
          {
            path: 'portal/dataFilter/:dataFilterId/account/:hqAccountId',
            children: [
              {
                path: '',
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'dashboard'
                  },
                  {
                    path: 'dashboard',
                    pathMatch: 'full'
                  },
                  {
                    path: 'earnings',
                    pathMatch: 'full'
                  },
                  {
                    path: 'opportunities',
                    pathMatch: 'full'
                  },
                  {
                    path: 'payments',
                    pathMatch: 'full'
                  },
                  {
                    path: 'sales',
                    pathMatch: 'full'
                  },
                  {
                    path: 'account-plans',
                    children: [
                      {
                        path: ''
                      },
                      {
                        path: 'create'
                      },
                      {
                        path: 'edit/:accountPlanId'
                      }
                    ]
                  },
                  {
                    path: 'account-profile',
                    pathMatch: 'full'
                  }
                ]
              }
            ]
          },
          {
            path: 'portal/notenant/:channelId',
              children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard'
              },
              {
                path: 'dashboard',
                pathMatch: 'full'
              },
              {
                path: 'approvals',
                pathMatch: 'full'
              },
              {
                path: 'earnings',
                pathMatch: 'full'
              },
              {
                path: 'opportunities',
                pathMatch: 'full'
              },
              {
                path: 'payments',
                pathMatch: 'full'
              },
              {
                path: 'sales',
                pathMatch: 'full'
              },
              {
                path: 'account-plans',
                children: [
                  {
                    path: ''
                  },
                  {
                    path: 'create'
                  },
                  {
                    path: 'edit/:accountPlanId'
                  }
                ]
              },
              {
                path: 'account-profile',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'portal/workflow-item'
          },
          {
            path: 'dashboard'
          },
          //Programs
          {
            path: 'programs',
            children: [
              {
                path: ''
              }
            ]
          },
          {
            path: 'programs/:id',
            children: [
              {
                path: 'sales',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'programs/page/:id/:name'
          },
          {
            path: 'programs/page/:id/:name/preview'
          },
          //Container
          {
            path: 'page/:id/:name'
          },
          {
            path: 'page/:id/:name/preview'
          },
          //demandplans
          {
            path: 'demandplans'
          },
          {
            path: 'demandplans/unauthorized'
          },
          {
            path: 'demandplans/auth-callback'
          },
          //payments
          {
            path: 'payments'
          },
          {
            path: 'payments/overview'
          },
          {
            path: 'payments/accounts'
          },
          {
            path: 'payments/payments'
          },
          {
            path: 'payments/upload'
          },
          {
            path: 'payments/unauthorized'
          },
          {
            path: 'payments/auth-callback'
          },
    ];

    return routes;
}
export class AppConfig {

    private static readonly configJson: string = "/config.json";
   
    public static applicationConfigurationUrl: string = '';
    static navBar: string = "";
    static forecastingTool: string = "";
    static accountTool: string = "";
    static adminTool: string = "";
    static container: string = "";
    static programTool: string = "";
    static paymentsTool: string = "";

    private constructor() {}

    public static async loadConfig(): Promise<void> {        
        const response = await fetch(this.configJson);
        const json = await response.json();

        this.mapToAppConfig(json);
    }

    private static mapToAppConfig(json): void {
        this.applicationConfigurationUrl = json.apis.applicationConfiguration;
        this.navBar = json.apis.navBar;
        this.forecastingTool = json.apis.forecastingTool;
        this.accountTool = json.apis.accountTool;
        this.container = json.apis.container;
        this.programTool = json.apis.programTool;
        this.adminTool = json.apis.adminTool;
        this.paymentsTool = json.apis.paymentsTool;
    }
}
import { AppConfig } from "./app-config";
import { ApplicationConfiguration } from "./application-configuration.model";
import { StaticApplicationConfiguration } from "./static-application-configuration.model";

// This is mostly just a 1:1 typescript copy of the previous iteration of what was in the MPM.Portal.SingleSpa api (/ApplicationConfiguration Controller and children components).
export async function getStaticConfiguration(): Promise<ApplicationConfiguration[]> {
    const baseConfigs: StaticApplicationConfiguration[] = [
        {
            activeWhen: ['/'],
            name: '@AGDATA/Container',
            baseLocation: AppConfig.container,
            mainFileName: 'main.js',
            scriptsFileName: 'scripts.js',
            stylesFileName: 'styles.js',
            isEnabled: true
        },
        {
            activeWhen: ['/'],
            name: '@AGDATA/navbar',
            baseLocation: AppConfig.navBar,
            mainFileName: 'main.js',
            scriptsFileName: 'scripts.js',
            stylesFileName: 'styles.js',
            isEnabled: true
        },
        {
            activeWhen: ['/demandplans'],
            name: '@AGDATA/forecastingtool',
            baseLocation: AppConfig.forecastingTool,
            mainFileName: 'main.js',
            scriptsFileName: 'scripts.js',
            stylesFileName: 'styles.js',
            isEnabled: true
        },
        {
            activeWhen: ['/accounts','/portal/dataFilter','/portal/approvals','/portal/notenant','/accountFilter','/dashboard'],
            name: '@AGDATA/Accounts',
            baseLocation: AppConfig.accountTool,
            mainFileName: 'main.js',
            scriptsFileName: 'scripts.js',
            stylesFileName: 'styles.js',
            isEnabled: true
        },
        {
            activeWhen: ['/portal-admin','/admin'],
            name: '@AGDATA/Admin',
            baseLocation: AppConfig.adminTool,
            mainFileName: 'main.js',
            scriptsFileName: 'scripts.js',
            stylesFileName: 'styles.js',
            isEnabled: true
        },
        {
            activeWhen: ['/programs'],
            name: '@AGDATA/Programs',
            baseLocation: AppConfig.programTool,
            mainFileName: 'main.js',
            scriptsFileName: 'scripts.js',
            stylesFileName: 'styles.js',
            isEnabled: true
        },
        {
            activeWhen: ['/payments'],
            name: '@AGDATA/paymentstool',
            baseLocation: AppConfig.paymentsTool,
            mainFileName: 'main.js',
            scriptsFileName: 'scripts.js',
            stylesFileName: 'styles.js',
            isEnabled: true
        },
    ];

    const result: ApplicationConfiguration[] = [];
    for (const config of baseConfigs) {
        result.push({
            name: config.name,
            location: `${config.baseLocation}${config.mainFileName}`,
            activeWhen: config.activeWhen,
            automaticRegister: true
        });

        if (config.scriptsFileName) {
            result.push({
                name: `${config.name}/Scripts`,
                location: `${config.baseLocation}${config.scriptsFileName}`,
                activeWhen: [''],
                automaticRegister: false
            });
        }

        if (config.stylesFileName) {
            result.push({
                name: `${config.name}/Styles`,
                location: `${config.baseLocation}${config.stylesFileName}`,
                activeWhen: [''],
                automaticRegister: false
            });
        }
    }

    return Promise.resolve(result);
}
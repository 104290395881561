import { AppConfig } from "./app-config";
import { ApplicationConfiguration } from "./application-configuration.model";
import { getStaticConfiguration } from "./static-configuration-service";

export async function getConfiguration(): Promise<ApplicationConfiguration[]> {
    
    // uncomment this when backend will be hosted
    //const response = await fetch(`${AppConfig.applicationConfigurationUrl}/api/ApplicationConfiguration`);
    //const data = await response.json() as ApplicationConfiguration[];
    
    // Comment this out when we switch back to some backend configuration in the future.
    const data = await getStaticConfiguration();

    return data;
}

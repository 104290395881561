import { LifeCycles, registerApplication } from "single-spa";
import { ApplicationConfiguration } from "./application-configuration.model";


export function registerApplications(configurations: ApplicationConfiguration[]) {    
    configurations.forEach(config => {
        if(!config.automaticRegister) {
            return;
        }

        registerApplication({
            name: config.name,
            app: () => System.import<LifeCycles>(config.name),
            activeWhen: config.activeWhen,
            customProps: () => {
                return { authToken: 'asdqwe123'};
            }
        })
    });
}